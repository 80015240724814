import _uniwheel from "./uniwheel";
import _controlIcons from "./control-icons";
import _utilities from "./utilities";
import _svgUtilities from "./svg-utilities";
import _shadowViewport from "./shadow-viewport";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Wheel = _uniwheel,
    ControlIcons = _controlIcons,
    Utils = _utilities,
    SvgUtils = _svgUtilities,
    ShadowViewport = _shadowViewport;

var SvgPanZoom = function (svg, options) {
  this.init(svg, options);
};

var optionsDefaults = {
  viewportSelector: ".svg-pan-zoom_viewport",
  // Viewport selector. Can be querySelector string or SVGElement
  panEnabled: true,
  // enable or disable panning (default enabled)
  controlIconsEnabled: false,
  // insert icons to give user an option in addition to mouse events to control pan/zoom (default disabled)
  zoomEnabled: true,
  // enable or disable zooming (default enabled)
  dblClickZoomEnabled: true,
  // enable or disable zooming by double clicking (default enabled)
  mouseWheelZoomEnabled: true,
  // enable or disable zooming by mouse wheel (default enabled)
  preventMouseEventsDefault: true,
  // enable or disable preventDefault for mouse events
  zoomScaleSensitivity: 0.1,
  // Zoom sensitivity
  minZoom: 0.5,
  // Minimum Zoom level
  maxZoom: 10,
  // Maximum Zoom level
  fit: true,
  // enable or disable viewport fit in SVG (default true)
  contain: false,
  // enable or disable viewport contain the svg (default false)
  center: true,
  // enable or disable viewport centering in SVG (default true)
  refreshRate: "auto",
  // Maximum number of frames per second (altering SVG's viewport)
  beforeZoom: null,
  onZoom: null,
  beforePan: null,
  onPan: null,
  customEventsHandler: null,
  eventsListenerElement: null,
  onUpdatedCTM: null
};
var passiveListenerOption = {
  passive: true
};

SvgPanZoom.prototype.init = function (svg, options) {
  var that = this || _global;
  (this || _global).svg = svg;
  (this || _global).defs = svg.querySelector("defs"); // Add default attributes to SVG

  SvgUtils.setupSvgAttributes((this || _global).svg); // Set options

  (this || _global).options = Utils.extend(Utils.extend({}, optionsDefaults), options); // Set default state

  (this || _global).state = "none"; // Get dimensions

  var boundingClientRectNormalized = SvgUtils.getBoundingClientRectNormalized(svg);
  (this || _global).width = boundingClientRectNormalized.width;
  (this || _global).height = boundingClientRectNormalized.height; // Init shadow viewport

  (this || _global).viewport = ShadowViewport(SvgUtils.getOrCreateViewport((this || _global).svg, (this || _global).options.viewportSelector), {
    svg: (this || _global).svg,
    width: (this || _global).width,
    height: (this || _global).height,
    fit: (this || _global).options.fit,
    contain: (this || _global).options.contain,
    center: (this || _global).options.center,
    refreshRate: (this || _global).options.refreshRate,
    // Put callbacks into functions as they can change through time
    beforeZoom: function (oldScale, newScale) {
      if (that.viewport && that.options.beforeZoom) {
        return that.options.beforeZoom(oldScale, newScale);
      }
    },
    onZoom: function (scale) {
      if (that.viewport && that.options.onZoom) {
        return that.options.onZoom(scale);
      }
    },
    beforePan: function (oldPoint, newPoint) {
      if (that.viewport && that.options.beforePan) {
        return that.options.beforePan(oldPoint, newPoint);
      }
    },
    onPan: function (point) {
      if (that.viewport && that.options.onPan) {
        return that.options.onPan(point);
      }
    },
    onUpdatedCTM: function (ctm) {
      if (that.viewport && that.options.onUpdatedCTM) {
        return that.options.onUpdatedCTM(ctm);
      }
    }
  }); // Wrap callbacks into public API context

  var publicInstance = this.getPublicInstance();
  publicInstance.setBeforeZoom((this || _global).options.beforeZoom);
  publicInstance.setOnZoom((this || _global).options.onZoom);
  publicInstance.setBeforePan((this || _global).options.beforePan);
  publicInstance.setOnPan((this || _global).options.onPan);
  publicInstance.setOnUpdatedCTM((this || _global).options.onUpdatedCTM);

  if ((this || _global).options.controlIconsEnabled) {
    ControlIcons.enable(this || _global);
  } // Init events handlers


  (this || _global).lastMouseWheelEventTime = Date.now();
  this.setupHandlers();
};
/**
 * Register event handlers
 */


SvgPanZoom.prototype.setupHandlers = function () {
  var that = this || _global,
      prevEvt = null; // use for touchstart event to detect double tap

  (this || _global).eventListeners = {
    // Mouse down group
    mousedown: function (evt) {
      var result = that.handleMouseDown(evt, prevEvt);
      prevEvt = evt;
      return result;
    },
    touchstart: function (evt) {
      var result = that.handleMouseDown(evt, prevEvt);
      prevEvt = evt;
      return result;
    },
    // Mouse up group
    mouseup: function (evt) {
      return that.handleMouseUp(evt);
    },
    touchend: function (evt) {
      return that.handleMouseUp(evt);
    },
    // Mouse move group
    mousemove: function (evt) {
      return that.handleMouseMove(evt);
    },
    touchmove: function (evt) {
      return that.handleMouseMove(evt);
    },
    // Mouse leave group
    mouseleave: function (evt) {
      return that.handleMouseUp(evt);
    },
    touchleave: function (evt) {
      return that.handleMouseUp(evt);
    },
    touchcancel: function (evt) {
      return that.handleMouseUp(evt);
    }
  }; // Init custom events handler if available
  // eslint-disable-next-line eqeqeq

  if ((this || _global).options.customEventsHandler != null) {
    (this || _global).options.customEventsHandler.init({
      svgElement: (this || _global).svg,
      eventsListenerElement: (this || _global).options.eventsListenerElement,
      instance: this.getPublicInstance()
    }); // Custom event handler may halt builtin listeners


    var haltEventListeners = (this || _global).options.customEventsHandler.haltEventListeners;

    if (haltEventListeners && haltEventListeners.length) {
      for (var i = haltEventListeners.length - 1; i >= 0; i--) {
        if ((this || _global).eventListeners.hasOwnProperty(haltEventListeners[i])) {
          delete (this || _global).eventListeners[haltEventListeners[i]];
        }
      }
    }
  } // Bind eventListeners


  for (var event in (this || _global).eventListeners) {
    // Attach event to eventsListenerElement or SVG if not available
    ((this || _global).options.eventsListenerElement || (this || _global).svg).addEventListener(event, (this || _global).eventListeners[event], !(this || _global).options.preventMouseEventsDefault ? passiveListenerOption : false);
  } // Zoom using mouse wheel


  if ((this || _global).options.mouseWheelZoomEnabled) {
    (this || _global).options.mouseWheelZoomEnabled = false; // set to false as enable will set it back to true

    this.enableMouseWheelZoom();
  }
};
/**
 * Enable ability to zoom using mouse wheel
 */


SvgPanZoom.prototype.enableMouseWheelZoom = function () {
  if (!(this || _global).options.mouseWheelZoomEnabled) {
    var that = this || _global; // Mouse wheel listener

    (this || _global).wheelListener = function (evt) {
      return that.handleMouseWheel(evt);
    }; // Bind wheelListener


    var isPassiveListener = !(this || _global).options.preventMouseEventsDefault;
    Wheel.on((this || _global).options.eventsListenerElement || (this || _global).svg, (this || _global).wheelListener, isPassiveListener);
    (this || _global).options.mouseWheelZoomEnabled = true;
  }
};
/**
 * Disable ability to zoom using mouse wheel
 */


SvgPanZoom.prototype.disableMouseWheelZoom = function () {
  if ((this || _global).options.mouseWheelZoomEnabled) {
    var isPassiveListener = !(this || _global).options.preventMouseEventsDefault;
    Wheel.off((this || _global).options.eventsListenerElement || (this || _global).svg, (this || _global).wheelListener, isPassiveListener);
    (this || _global).options.mouseWheelZoomEnabled = false;
  }
};
/**
 * Handle mouse wheel event
 *
 * @param  {Event} evt
 */


SvgPanZoom.prototype.handleMouseWheel = function (evt) {
  if (!(this || _global).options.zoomEnabled || (this || _global).state !== "none") {
    return;
  }

  if ((this || _global).options.preventMouseEventsDefault) {
    if (evt.preventDefault) {
      evt.preventDefault();
    } else {
      evt.returnValue = false;
    }
  } // Default delta in case that deltaY is not available


  var delta = evt.deltaY || 1,
      timeDelta = Date.now() - (this || _global).lastMouseWheelEventTime,
      divider = 3 + Math.max(0, 30 - timeDelta); // Update cache


  (this || _global).lastMouseWheelEventTime = Date.now(); // Make empirical adjustments for browsers that give deltaY in pixels (deltaMode=0)

  if ("deltaMode" in evt && evt.deltaMode === 0 && evt.wheelDelta) {
    delta = evt.deltaY === 0 ? 0 : Math.abs(evt.wheelDelta) / evt.deltaY;
  }

  delta = -0.3 < delta && delta < 0.3 ? delta : (delta > 0 ? 1 : -1) * Math.log(Math.abs(delta) + 10) / divider;

  var inversedScreenCTM = (this || _global).svg.getScreenCTM().inverse(),
      relativeMousePoint = SvgUtils.getEventPoint(evt, (this || _global).svg).matrixTransform(inversedScreenCTM),
      zoom = Math.pow(1 + (this || _global).options.zoomScaleSensitivity, -1 * delta); // multiplying by neg. 1 so as to make zoom in/out behavior match Google maps behavior


  this.zoomAtPoint(zoom, relativeMousePoint);
};
/**
 * Zoom in at a SVG point
 *
 * @param  {SVGPoint} point
 * @param  {Float} zoomScale    Number representing how much to zoom
 * @param  {Boolean} zoomAbsolute Default false. If true, zoomScale is treated as an absolute value.
 *                                Otherwise, zoomScale is treated as a multiplied (e.g. 1.10 would zoom in 10%)
 */


SvgPanZoom.prototype.zoomAtPoint = function (zoomScale, point, zoomAbsolute) {
  var originalState = (this || _global).viewport.getOriginalState();

  if (!zoomAbsolute) {
    // Fit zoomScale in set bounds
    if (this.getZoom() * zoomScale < (this || _global).options.minZoom * originalState.zoom) {
      zoomScale = (this || _global).options.minZoom * originalState.zoom / this.getZoom();
    } else if (this.getZoom() * zoomScale > (this || _global).options.maxZoom * originalState.zoom) {
      zoomScale = (this || _global).options.maxZoom * originalState.zoom / this.getZoom();
    }
  } else {
    // Fit zoomScale in set bounds
    zoomScale = Math.max((this || _global).options.minZoom * originalState.zoom, Math.min((this || _global).options.maxZoom * originalState.zoom, zoomScale)); // Find relative scale to achieve desired scale

    zoomScale = zoomScale / this.getZoom();
  }

  var oldCTM = (this || _global).viewport.getCTM(),
      relativePoint = point.matrixTransform(oldCTM.inverse()),
      modifier = (this || _global).svg.createSVGMatrix().translate(relativePoint.x, relativePoint.y).scale(zoomScale).translate(-relativePoint.x, -relativePoint.y),
      newCTM = oldCTM.multiply(modifier);

  if (newCTM.a !== oldCTM.a) {
    (this || _global).viewport.setCTM(newCTM);
  }
};
/**
 * Zoom at center point
 *
 * @param  {Float} scale
 * @param  {Boolean} absolute Marks zoom scale as relative or absolute
 */


SvgPanZoom.prototype.zoom = function (scale, absolute) {
  this.zoomAtPoint(scale, SvgUtils.getSvgCenterPoint((this || _global).svg, (this || _global).width, (this || _global).height), absolute);
};
/**
 * Zoom used by public instance
 *
 * @param  {Float} scale
 * @param  {Boolean} absolute Marks zoom scale as relative or absolute
 */


SvgPanZoom.prototype.publicZoom = function (scale, absolute) {
  if (absolute) {
    scale = this.computeFromRelativeZoom(scale);
  }

  this.zoom(scale, absolute);
};
/**
 * Zoom at point used by public instance
 *
 * @param  {Float} scale
 * @param  {SVGPoint|Object} point    An object that has x and y attributes
 * @param  {Boolean} absolute Marks zoom scale as relative or absolute
 */


SvgPanZoom.prototype.publicZoomAtPoint = function (scale, point, absolute) {
  if (absolute) {
    // Transform zoom into a relative value
    scale = this.computeFromRelativeZoom(scale);
  } // If not a SVGPoint but has x and y then create a SVGPoint


  if (Utils.getType(point) !== "SVGPoint") {
    if ("x" in point && "y" in point) {
      point = SvgUtils.createSVGPoint((this || _global).svg, point.x, point.y);
    } else {
      throw new Error("Given point is invalid");
    }
  }

  this.zoomAtPoint(scale, point, absolute);
};
/**
 * Get zoom scale
 *
 * @return {Float} zoom scale
 */


SvgPanZoom.prototype.getZoom = function () {
  return (this || _global).viewport.getZoom();
};
/**
 * Get zoom scale for public usage
 *
 * @return {Float} zoom scale
 */


SvgPanZoom.prototype.getRelativeZoom = function () {
  return (this || _global).viewport.getRelativeZoom();
};
/**
 * Compute actual zoom from public zoom
 *
 * @param  {Float} zoom
 * @return {Float} zoom scale
 */


SvgPanZoom.prototype.computeFromRelativeZoom = function (zoom) {
  return zoom * (this || _global).viewport.getOriginalState().zoom;
};
/**
 * Set zoom to initial state
 */


SvgPanZoom.prototype.resetZoom = function () {
  var originalState = (this || _global).viewport.getOriginalState();

  this.zoom(originalState.zoom, true);
};
/**
 * Set pan to initial state
 */


SvgPanZoom.prototype.resetPan = function () {
  this.pan((this || _global).viewport.getOriginalState());
};
/**
 * Set pan and zoom to initial state
 */


SvgPanZoom.prototype.reset = function () {
  this.resetZoom();
  this.resetPan();
};
/**
 * Handle double click event
 * See handleMouseDown() for alternate detection method
 *
 * @param {Event} evt
 */


SvgPanZoom.prototype.handleDblClick = function (evt) {
  if ((this || _global).options.preventMouseEventsDefault) {
    if (evt.preventDefault) {
      evt.preventDefault();
    } else {
      evt.returnValue = false;
    }
  } // Check if target was a control button


  if ((this || _global).options.controlIconsEnabled) {
    var targetClass = evt.target.getAttribute("class") || "";

    if (targetClass.indexOf("svg-pan-zoom-control") > -1) {
      return false;
    }
  }

  var zoomFactor;

  if (evt.shiftKey) {
    zoomFactor = 1 / ((1 + (this || _global).options.zoomScaleSensitivity) * 2); // zoom out when shift key pressed
  } else {
    zoomFactor = (1 + (this || _global).options.zoomScaleSensitivity) * 2;
  }

  var point = SvgUtils.getEventPoint(evt, (this || _global).svg).matrixTransform((this || _global).svg.getScreenCTM().inverse());
  this.zoomAtPoint(zoomFactor, point);
};
/**
 * Handle click event
 *
 * @param {Event} evt
 */


SvgPanZoom.prototype.handleMouseDown = function (evt, prevEvt) {
  if ((this || _global).options.preventMouseEventsDefault) {
    if (evt.preventDefault) {
      evt.preventDefault();
    } else {
      evt.returnValue = false;
    }
  }

  Utils.mouseAndTouchNormalize(evt, (this || _global).svg); // Double click detection; more consistent than ondblclick

  if ((this || _global).options.dblClickZoomEnabled && Utils.isDblClick(evt, prevEvt)) {
    this.handleDblClick(evt);
  } else {
    // Pan mode
    (this || _global).state = "pan";
    (this || _global).firstEventCTM = (this || _global).viewport.getCTM();
    (this || _global).stateOrigin = SvgUtils.getEventPoint(evt, (this || _global).svg).matrixTransform((this || _global).firstEventCTM.inverse());
  }
};
/**
 * Handle mouse move event
 *
 * @param  {Event} evt
 */


SvgPanZoom.prototype.handleMouseMove = function (evt) {
  if ((this || _global).options.preventMouseEventsDefault) {
    if (evt.preventDefault) {
      evt.preventDefault();
    } else {
      evt.returnValue = false;
    }
  }

  if ((this || _global).state === "pan" && (this || _global).options.panEnabled) {
    // Pan mode
    var point = SvgUtils.getEventPoint(evt, (this || _global).svg).matrixTransform((this || _global).firstEventCTM.inverse()),
        viewportCTM = (this || _global).firstEventCTM.translate(point.x - (this || _global).stateOrigin.x, point.y - (this || _global).stateOrigin.y);

    (this || _global).viewport.setCTM(viewportCTM);
  }
};
/**
 * Handle mouse button release event
 *
 * @param {Event} evt
 */


SvgPanZoom.prototype.handleMouseUp = function (evt) {
  if ((this || _global).options.preventMouseEventsDefault) {
    if (evt.preventDefault) {
      evt.preventDefault();
    } else {
      evt.returnValue = false;
    }
  }

  if ((this || _global).state === "pan") {
    // Quit pan mode
    (this || _global).state = "none";
  }
};
/**
 * Adjust viewport size (only) so it will fit in SVG
 * Does not center image
 */


SvgPanZoom.prototype.fit = function () {
  var viewBox = (this || _global).viewport.getViewBox(),
      newScale = Math.min((this || _global).width / viewBox.width, (this || _global).height / viewBox.height);

  this.zoom(newScale, true);
};
/**
 * Adjust viewport size (only) so it will contain the SVG
 * Does not center image
 */


SvgPanZoom.prototype.contain = function () {
  var viewBox = (this || _global).viewport.getViewBox(),
      newScale = Math.max((this || _global).width / viewBox.width, (this || _global).height / viewBox.height);

  this.zoom(newScale, true);
};
/**
 * Adjust viewport pan (only) so it will be centered in SVG
 * Does not zoom/fit/contain image
 */


SvgPanZoom.prototype.center = function () {
  var viewBox = (this || _global).viewport.getViewBox(),
      offsetX = ((this || _global).width - (viewBox.width + viewBox.x * 2) * this.getZoom()) * 0.5,
      offsetY = ((this || _global).height - (viewBox.height + viewBox.y * 2) * this.getZoom()) * 0.5;

  this.getPublicInstance().pan({
    x: offsetX,
    y: offsetY
  });
};
/**
 * Update content cached BorderBox
 * Use when viewport contents change
 */


SvgPanZoom.prototype.updateBBox = function () {
  (this || _global).viewport.simpleViewBoxCache();
};
/**
 * Pan to a rendered position
 *
 * @param  {Object} point {x: 0, y: 0}
 */


SvgPanZoom.prototype.pan = function (point) {
  var viewportCTM = (this || _global).viewport.getCTM();

  viewportCTM.e = point.x;
  viewportCTM.f = point.y;

  (this || _global).viewport.setCTM(viewportCTM);
};
/**
 * Relatively pan the graph by a specified rendered position vector
 *
 * @param  {Object} point {x: 0, y: 0}
 */


SvgPanZoom.prototype.panBy = function (point) {
  var viewportCTM = (this || _global).viewport.getCTM();

  viewportCTM.e += point.x;
  viewportCTM.f += point.y;

  (this || _global).viewport.setCTM(viewportCTM);
};
/**
 * Get pan vector
 *
 * @return {Object} {x: 0, y: 0}
 */


SvgPanZoom.prototype.getPan = function () {
  var state = (this || _global).viewport.getState();

  return {
    x: state.x,
    y: state.y
  };
};
/**
 * Recalculates cached svg dimensions and controls position
 */


SvgPanZoom.prototype.resize = function () {
  // Get dimensions
  var boundingClientRectNormalized = SvgUtils.getBoundingClientRectNormalized((this || _global).svg);
  (this || _global).width = boundingClientRectNormalized.width;
  (this || _global).height = boundingClientRectNormalized.height; // Recalculate original state

  var viewport = (this || _global).viewport;
  viewport.options.width = (this || _global).width;
  viewport.options.height = (this || _global).height;
  viewport.processCTM(); // Reposition control icons by re-enabling them

  if ((this || _global).options.controlIconsEnabled) {
    this.getPublicInstance().disableControlIcons();
    this.getPublicInstance().enableControlIcons();
  }
};
/**
 * Unbind mouse events, free callbacks and destroy public instance
 */


SvgPanZoom.prototype.destroy = function () {
  var that = this || _global; // Free callbacks

  (this || _global).beforeZoom = null;
  (this || _global).onZoom = null;
  (this || _global).beforePan = null;
  (this || _global).onPan = null;
  (this || _global).onUpdatedCTM = null; // Destroy custom event handlers
  // eslint-disable-next-line eqeqeq

  if ((this || _global).options.customEventsHandler != null) {
    (this || _global).options.customEventsHandler.destroy({
      svgElement: (this || _global).svg,
      eventsListenerElement: (this || _global).options.eventsListenerElement,
      instance: this.getPublicInstance()
    });
  } // Unbind eventListeners


  for (var event in (this || _global).eventListeners) {
    ((this || _global).options.eventsListenerElement || (this || _global).svg).removeEventListener(event, (this || _global).eventListeners[event], !(this || _global).options.preventMouseEventsDefault ? passiveListenerOption : false);
  } // Unbind wheelListener


  this.disableMouseWheelZoom(); // Remove control icons

  this.getPublicInstance().disableControlIcons(); // Reset zoom and pan

  this.reset(); // Remove instance from instancesStore

  instancesStore = instancesStore.filter(function (instance) {
    return instance.svg !== that.svg;
  }); // Delete options and its contents

  delete (this || _global).options; // Delete viewport to make public shadow viewport functions uncallable

  delete (this || _global).viewport; // Destroy public instance and rewrite getPublicInstance

  delete (this || _global).publicInstance;
  delete (this || _global).pi;

  (this || _global).getPublicInstance = function () {
    return null;
  };
};
/**
 * Returns a public instance object
 *
 * @return {Object} Public instance object
 */


SvgPanZoom.prototype.getPublicInstance = function () {
  var that = this || _global; // Create cache

  if (!(this || _global).publicInstance) {
    (this || _global).publicInstance = (this || _global).pi = {
      // Pan
      enablePan: function () {
        that.options.panEnabled = true;
        return that.pi;
      },
      disablePan: function () {
        that.options.panEnabled = false;
        return that.pi;
      },
      isPanEnabled: function () {
        return !!that.options.panEnabled;
      },
      pan: function (point) {
        that.pan(point);
        return that.pi;
      },
      panBy: function (point) {
        that.panBy(point);
        return that.pi;
      },
      getPan: function () {
        return that.getPan();
      },
      // Pan event
      setBeforePan: function (fn) {
        that.options.beforePan = fn === null ? null : Utils.proxy(fn, that.publicInstance);
        return that.pi;
      },
      setOnPan: function (fn) {
        that.options.onPan = fn === null ? null : Utils.proxy(fn, that.publicInstance);
        return that.pi;
      },
      // Zoom and Control Icons
      enableZoom: function () {
        that.options.zoomEnabled = true;
        return that.pi;
      },
      disableZoom: function () {
        that.options.zoomEnabled = false;
        return that.pi;
      },
      isZoomEnabled: function () {
        return !!that.options.zoomEnabled;
      },
      enableControlIcons: function () {
        if (!that.options.controlIconsEnabled) {
          that.options.controlIconsEnabled = true;
          ControlIcons.enable(that);
        }

        return that.pi;
      },
      disableControlIcons: function () {
        if (that.options.controlIconsEnabled) {
          that.options.controlIconsEnabled = false;
          ControlIcons.disable(that);
        }

        return that.pi;
      },
      isControlIconsEnabled: function () {
        return !!that.options.controlIconsEnabled;
      },
      // Double click zoom
      enableDblClickZoom: function () {
        that.options.dblClickZoomEnabled = true;
        return that.pi;
      },
      disableDblClickZoom: function () {
        that.options.dblClickZoomEnabled = false;
        return that.pi;
      },
      isDblClickZoomEnabled: function () {
        return !!that.options.dblClickZoomEnabled;
      },
      // Mouse wheel zoom
      enableMouseWheelZoom: function () {
        that.enableMouseWheelZoom();
        return that.pi;
      },
      disableMouseWheelZoom: function () {
        that.disableMouseWheelZoom();
        return that.pi;
      },
      isMouseWheelZoomEnabled: function () {
        return !!that.options.mouseWheelZoomEnabled;
      },
      // Zoom scale and bounds
      setZoomScaleSensitivity: function (scale) {
        that.options.zoomScaleSensitivity = scale;
        return that.pi;
      },
      setMinZoom: function (zoom) {
        that.options.minZoom = zoom;
        return that.pi;
      },
      setMaxZoom: function (zoom) {
        that.options.maxZoom = zoom;
        return that.pi;
      },
      // Zoom event
      setBeforeZoom: function (fn) {
        that.options.beforeZoom = fn === null ? null : Utils.proxy(fn, that.publicInstance);
        return that.pi;
      },
      setOnZoom: function (fn) {
        that.options.onZoom = fn === null ? null : Utils.proxy(fn, that.publicInstance);
        return that.pi;
      },
      // Zooming
      zoom: function (scale) {
        that.publicZoom(scale, true);
        return that.pi;
      },
      zoomBy: function (scale) {
        that.publicZoom(scale, false);
        return that.pi;
      },
      zoomAtPoint: function (scale, point) {
        that.publicZoomAtPoint(scale, point, true);
        return that.pi;
      },
      zoomAtPointBy: function (scale, point) {
        that.publicZoomAtPoint(scale, point, false);
        return that.pi;
      },
      zoomIn: function () {
        this.zoomBy(1 + that.options.zoomScaleSensitivity);
        return that.pi;
      },
      zoomOut: function () {
        this.zoomBy(1 / (1 + that.options.zoomScaleSensitivity));
        return that.pi;
      },
      getZoom: function () {
        return that.getRelativeZoom();
      },
      // CTM update
      setOnUpdatedCTM: function (fn) {
        that.options.onUpdatedCTM = fn === null ? null : Utils.proxy(fn, that.publicInstance);
        return that.pi;
      },
      // Reset
      resetZoom: function () {
        that.resetZoom();
        return that.pi;
      },
      resetPan: function () {
        that.resetPan();
        return that.pi;
      },
      reset: function () {
        that.reset();
        return that.pi;
      },
      // Fit, Contain and Center
      fit: function () {
        that.fit();
        return that.pi;
      },
      contain: function () {
        that.contain();
        return that.pi;
      },
      center: function () {
        that.center();
        return that.pi;
      },
      // Size and Resize
      updateBBox: function () {
        that.updateBBox();
        return that.pi;
      },
      resize: function () {
        that.resize();
        return that.pi;
      },
      getSizes: function () {
        return {
          width: that.width,
          height: that.height,
          realZoom: that.getZoom(),
          viewBox: that.viewport.getViewBox()
        };
      },
      // Destroy
      destroy: function () {
        that.destroy();
        return that.pi;
      }
    };
  }

  return (this || _global).publicInstance;
};
/**
 * Stores pairs of instances of SvgPanZoom and SVG
 * Each pair is represented by an object {svg: SVGSVGElement, instance: SvgPanZoom}
 *
 * @type {Array}
 */


var instancesStore = [];

var svgPanZoom = function (elementOrSelector, options) {
  var svg = Utils.getSvg(elementOrSelector);

  if (svg === null) {
    return null;
  } else {
    // Look for existent instance
    for (var i = instancesStore.length - 1; i >= 0; i--) {
      if (instancesStore[i].svg === svg) {
        return instancesStore[i].instance.getPublicInstance();
      }
    } // If instance not found - create one


    instancesStore.push({
      svg: svg,
      instance: new SvgPanZoom(svg, options)
    }); // Return just pushed instance

    return instancesStore[instancesStore.length - 1].instance.getPublicInstance();
  }
};

exports = svgPanZoom;
export default exports;