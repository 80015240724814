import _svgUtilities from "./svg-utilities";
import _utilities from "./utilities";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var SvgUtils = _svgUtilities,
    Utils = _utilities;

var ShadowViewport = function (viewport, options) {
  this.init(viewport, options);
};
/**
 * Initialization
 *
 * @param  {SVGElement} viewport
 * @param  {Object} options
 */


ShadowViewport.prototype.init = function (viewport, options) {
  // DOM Elements
  (this || _global).viewport = viewport;
  (this || _global).options = options; // State cache

  (this || _global).originalState = {
    zoom: 1,
    x: 0,
    y: 0
  };
  (this || _global).activeState = {
    zoom: 1,
    x: 0,
    y: 0
  };
  (this || _global).updateCTMCached = Utils.proxy((this || _global).updateCTM, this || _global); // Create a custom requestAnimationFrame taking in account refreshRate

  (this || _global).requestAnimationFrame = Utils.createRequestAnimationFrame((this || _global).options.refreshRate); // ViewBox

  (this || _global).viewBox = {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  };
  this.cacheViewBox(); // Process CTM

  var newCTM = this.processCTM(); // Update viewport CTM and cache zoom and pan

  this.setCTM(newCTM); // Update CTM in this frame

  this.updateCTM();
};
/**
 * Cache initial viewBox value
 * If no viewBox is defined, then use viewport size/position instead for viewBox values
 */


ShadowViewport.prototype.cacheViewBox = function () {
  var svgViewBox = (this || _global).options.svg.getAttribute("viewBox");

  if (svgViewBox) {
    var viewBoxValues = svgViewBox.split(/[\s\,]/).filter(function (v) {
      return v;
    }).map(parseFloat); // Cache viewbox x and y offset

    (this || _global).viewBox.x = viewBoxValues[0];
    (this || _global).viewBox.y = viewBoxValues[1];
    (this || _global).viewBox.width = viewBoxValues[2];
    (this || _global).viewBox.height = viewBoxValues[3];
    var zoom = Math.min((this || _global).options.width / (this || _global).viewBox.width, (this || _global).options.height / (this || _global).viewBox.height); // Update active state

    (this || _global).activeState.zoom = zoom;
    (this || _global).activeState.x = ((this || _global).options.width - (this || _global).viewBox.width * zoom) / 2;
    (this || _global).activeState.y = ((this || _global).options.height - (this || _global).viewBox.height * zoom) / 2; // Force updating CTM

    this.updateCTMOnNextFrame();

    (this || _global).options.svg.removeAttribute("viewBox");
  } else {
    this.simpleViewBoxCache();
  }
};
/**
 * Recalculate viewport sizes and update viewBox cache
 */


ShadowViewport.prototype.simpleViewBoxCache = function () {
  var bBox = (this || _global).viewport.getBBox();

  (this || _global).viewBox.x = bBox.x;
  (this || _global).viewBox.y = bBox.y;
  (this || _global).viewBox.width = bBox.width;
  (this || _global).viewBox.height = bBox.height;
};
/**
 * Returns a viewbox object. Safe to alter
 *
 * @return {Object} viewbox object
 */


ShadowViewport.prototype.getViewBox = function () {
  return Utils.extend({}, (this || _global).viewBox);
};
/**
 * Get initial zoom and pan values. Save them into originalState
 * Parses viewBox attribute to alter initial sizes
 *
 * @return {CTM} CTM object based on options
 */


ShadowViewport.prototype.processCTM = function () {
  var newCTM = this.getCTM();

  if ((this || _global).options.fit || (this || _global).options.contain) {
    var newScale;

    if ((this || _global).options.fit) {
      newScale = Math.min((this || _global).options.width / (this || _global).viewBox.width, (this || _global).options.height / (this || _global).viewBox.height);
    } else {
      newScale = Math.max((this || _global).options.width / (this || _global).viewBox.width, (this || _global).options.height / (this || _global).viewBox.height);
    }

    newCTM.a = newScale; //x-scale

    newCTM.d = newScale; //y-scale

    newCTM.e = -(this || _global).viewBox.x * newScale; //x-transform

    newCTM.f = -(this || _global).viewBox.y * newScale; //y-transform
  }

  if ((this || _global).options.center) {
    var offsetX = ((this || _global).options.width - ((this || _global).viewBox.width + (this || _global).viewBox.x * 2) * newCTM.a) * 0.5,
        offsetY = ((this || _global).options.height - ((this || _global).viewBox.height + (this || _global).viewBox.y * 2) * newCTM.a) * 0.5;
    newCTM.e = offsetX;
    newCTM.f = offsetY;
  } // Cache initial values. Based on activeState and fix+center opitons


  (this || _global).originalState.zoom = newCTM.a;
  (this || _global).originalState.x = newCTM.e;
  (this || _global).originalState.y = newCTM.f;
  return newCTM;
};
/**
 * Return originalState object. Safe to alter
 *
 * @return {Object}
 */


ShadowViewport.prototype.getOriginalState = function () {
  return Utils.extend({}, (this || _global).originalState);
};
/**
 * Return actualState object. Safe to alter
 *
 * @return {Object}
 */


ShadowViewport.prototype.getState = function () {
  return Utils.extend({}, (this || _global).activeState);
};
/**
 * Get zoom scale
 *
 * @return {Float} zoom scale
 */


ShadowViewport.prototype.getZoom = function () {
  return (this || _global).activeState.zoom;
};
/**
 * Get zoom scale for pubilc usage
 *
 * @return {Float} zoom scale
 */


ShadowViewport.prototype.getRelativeZoom = function () {
  return (this || _global).activeState.zoom / (this || _global).originalState.zoom;
};
/**
 * Compute zoom scale for pubilc usage
 *
 * @return {Float} zoom scale
 */


ShadowViewport.prototype.computeRelativeZoom = function (scale) {
  return scale / (this || _global).originalState.zoom;
};
/**
 * Get pan
 *
 * @return {Object}
 */


ShadowViewport.prototype.getPan = function () {
  return {
    x: (this || _global).activeState.x,
    y: (this || _global).activeState.y
  };
};
/**
 * Return cached viewport CTM value that can be safely modified
 *
 * @return {SVGMatrix}
 */


ShadowViewport.prototype.getCTM = function () {
  var safeCTM = (this || _global).options.svg.createSVGMatrix(); // Copy values manually as in FF they are not itterable


  safeCTM.a = (this || _global).activeState.zoom;
  safeCTM.b = 0;
  safeCTM.c = 0;
  safeCTM.d = (this || _global).activeState.zoom;
  safeCTM.e = (this || _global).activeState.x;
  safeCTM.f = (this || _global).activeState.y;
  return safeCTM;
};
/**
 * Set a new CTM
 *
 * @param {SVGMatrix} newCTM
 */


ShadowViewport.prototype.setCTM = function (newCTM) {
  var willZoom = this.isZoomDifferent(newCTM),
      willPan = this.isPanDifferent(newCTM);

  if (willZoom || willPan) {
    // Before zoom
    if (willZoom) {
      // If returns false then cancel zooming
      if ((this || _global).options.beforeZoom(this.getRelativeZoom(), this.computeRelativeZoom(newCTM.a)) === false) {
        newCTM.a = newCTM.d = (this || _global).activeState.zoom;
        willZoom = false;
      } else {
        this.updateCache(newCTM);

        (this || _global).options.onZoom(this.getRelativeZoom());
      }
    } // Before pan


    if (willPan) {
      var preventPan = (this || _global).options.beforePan(this.getPan(), {
        x: newCTM.e,
        y: newCTM.f
      }),
          // If prevent pan is an object
      preventPanX = false,
          preventPanY = false; // If prevent pan is Boolean false


      if (preventPan === false) {
        // Set x and y same as before
        newCTM.e = this.getPan().x;
        newCTM.f = this.getPan().y;
        preventPanX = preventPanY = true;
      } else if (Utils.isObject(preventPan)) {
        // Check for X axes attribute
        if (preventPan.x === false) {
          // Prevent panning on x axes
          newCTM.e = this.getPan().x;
          preventPanX = true;
        } else if (Utils.isNumber(preventPan.x)) {
          // Set a custom pan value
          newCTM.e = preventPan.x;
        } // Check for Y axes attribute


        if (preventPan.y === false) {
          // Prevent panning on x axes
          newCTM.f = this.getPan().y;
          preventPanY = true;
        } else if (Utils.isNumber(preventPan.y)) {
          // Set a custom pan value
          newCTM.f = preventPan.y;
        }
      } // Update willPan flag
      // Check if newCTM is still different


      if (preventPanX && preventPanY || !this.isPanDifferent(newCTM)) {
        willPan = false;
      } else {
        this.updateCache(newCTM);

        (this || _global).options.onPan(this.getPan());
      }
    } // Check again if should zoom or pan


    if (willZoom || willPan) {
      this.updateCTMOnNextFrame();
    }
  }
};

ShadowViewport.prototype.isZoomDifferent = function (newCTM) {
  return (this || _global).activeState.zoom !== newCTM.a;
};

ShadowViewport.prototype.isPanDifferent = function (newCTM) {
  return (this || _global).activeState.x !== newCTM.e || (this || _global).activeState.y !== newCTM.f;
};
/**
 * Update cached CTM and active state
 *
 * @param {SVGMatrix} newCTM
 */


ShadowViewport.prototype.updateCache = function (newCTM) {
  (this || _global).activeState.zoom = newCTM.a;
  (this || _global).activeState.x = newCTM.e;
  (this || _global).activeState.y = newCTM.f;
};

ShadowViewport.prototype.pendingUpdate = false;
/**
 * Place a request to update CTM on next Frame
 */

ShadowViewport.prototype.updateCTMOnNextFrame = function () {
  if (!(this || _global).pendingUpdate) {
    // Lock
    (this || _global).pendingUpdate = true; // Throttle next update

    (this || _global).requestAnimationFrame.call(window, (this || _global).updateCTMCached);
  }
};
/**
 * Update viewport CTM with cached CTM
 */


ShadowViewport.prototype.updateCTM = function () {
  var ctm = this.getCTM(); // Updates SVG element

  SvgUtils.setCTM((this || _global).viewport, ctm, (this || _global).defs); // Free the lock

  (this || _global).pendingUpdate = false; // Notify about the update

  if ((this || _global).options.onUpdatedCTM) {
    (this || _global).options.onUpdatedCTM(ctm);
  }
};

exports = function (viewport, options) {
  return new ShadowViewport(viewport, options);
};

export default exports;